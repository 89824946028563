import { SessionDto, TokenResDto } from '@sentinel/hooks';
import {
  broadcastToApp,
  cleanUpStaleCookies,
  isEmbeddedBrowser,
  isNativeMobile,
  logout
} from '@vestwell-frontend/helpers';
import { useSecureStorage } from '@vestwell-frontend/hooks';

import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

type Auth = {
  isNative: boolean;
  token: string;
  setToken: (token: TokenResDto | string) => void;
  updateToken: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<StoreApi<Auth>>(null);

export const useAuth = () => {
  const store = useContext(AuthContext);
  return useStore(store) as Auth;
};

export const AuthProvider: FC<AuthProviderProps> = props => {
  const [mobileToken, setMobileToken] = useSecureStorage('token');
  const [mobileTokenExpiration, setMobileTokenExpiration] =
    useSecureStorage('tokenExpiration');
  const [, setWhitelabelSubdomain] = useSecureStorage('WHITELABEL_SUBDOMAIN');
  const [isEmbedded, setIsEmbedded] = useState(false);
  const [isNativeApp, setIsNativeApp] = useState(false);
  const [token, setAuthToken] = useState<string | null>();
  const [tokenExpiration, setAuthTokenExpiration] = useState<string | null>(
    null
  );
  const [isInitializing, setIsInitializing] = useState(true);
  const [isUpdatingToken, setIsUpdatingToken] = useState(false);

  const domain = useMemo(() => {
    return window.location.host.split('.').slice(-2).join('.');
  }, [window.location.host]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const embeddedResult = await isEmbeddedBrowser();
        const isNative = await isNativeMobile();

        setIsEmbedded(embeddedResult);
        setIsNativeApp(isNative);
      } catch (error) {
        console.error('Error checking environment:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // This is in essence our react app first load handler
    if (isNativeApp && mobileToken && mobileTokenExpiration) {
      const tokenData = JSON.parse(mobileToken);
      const expiration = JSON.parse(mobileTokenExpiration);

      if (
        tokenData?.access_token &&
        tokenData?.token_type &&
        expiration?.expiration
      ) {
        setAuthToken(`${tokenData.token_type} ${tokenData.access_token}`);
        setAuthTokenExpiration(expiration.expiration);
        const decodedToken: SessionDto = jwtDecode(tokenData.access_token);
        if (decodedToken?.subDomain) {
          setWhitelabelSubdomain(decodedToken.subDomain);
          axios.defaults.headers.common['x-mobile-subdomain'] =
            decodedToken.subDomain;
        }
        axios.defaults.headers.common['Authorization'] =
          `${tokenData.token_type} ${tokenData.access_token}`;
      }
      setIsInitializing(false);
    } else {
      const isSameHost = Cookies.get('tokenHost') === window.location.host;
      const accessToken = isSameHost ? Cookies.get('token') : null;
      setAuthToken(accessToken);
      setAuthTokenExpiration(
        isSameHost ? Cookies.get('tokenExpiration') : null
      );

      if (!isSameHost) {
        Cookies.set('tokenHost', window.location.host, {
          domain: `.${domain}`,
          expires: new Date(new Date().getTime() + 30 * 60 * 1000),
          path: '/'
        });
      }

      if (accessToken) {
        axios.defaults.headers.common['Authorization'] = accessToken;
      }

      // On desktop, if host changes or token expires, we lose our token.
      // This means we need to fetch a new one if we have a refresh token.
      if (!accessToken && Cookies.get('refreshToken')) {
        // grab a new token which will set isInitializing to false when done
        authConfigStore.getState().updateToken();
      } else {
        // immediately set initialized since we don't need to fetch a refreshed token
        setIsInitializing(false);
      }
    }
  }, [domain, isNativeApp, mobileToken, mobileTokenExpiration]);

  const authConfigStore = createStore<Auth>()(() => ({
    isNative: isNativeApp,
    setToken: token => {
      if (!token) {
        cleanUpStaleCookies();
        return;
      }

      // Set token expiration to 13 minutes (to be safe, real expiration is 15 minutes)
      const expirationTime = new Date(new Date().getTime() + 13 * 60 * 1000);

      if (
        typeof token !== 'string' &&
        token?.token_type &&
        token?.access_token
      ) {
        if (isNativeApp) {
          setMobileToken(JSON.stringify(token));
          setMobileTokenExpiration(
            JSON.stringify({ expiration: expirationTime.toISOString() })
          );
          const decodedToken: SessionDto = jwtDecode(token.access_token);
          if (decodedToken?.subDomain) {
            setWhitelabelSubdomain(decodedToken.subDomain);
            axios.defaults.headers.common['x-mobile-subdomain'] =
              decodedToken.subDomain;
          }
        }

        if (isEmbedded) {
          broadcastToApp('JWT', {
            ...token,
            expirationTime: expirationTime.toISOString()
          });
        }
      }

      const accessToken =
        typeof token !== 'string' && token?.token_type && token?.access_token
          ? `${token.token_type} ${token.access_token}`
          : // password case
            typeof token === 'string'
            ? token
            : null;

      const refreshToken =
        typeof token !== 'string' ? token?.refresh_token : null;

      setAuthToken(accessToken);
      setAuthTokenExpiration(expirationTime.toISOString());
      Cookies.set('token', accessToken, {
        domain: `${window.location.host}`,
        expires: expirationTime,
        path: '/'
      });
      Cookies.set('tokenExpiration', expirationTime, {
        domain: `${window.location.host}`,
        expires: expirationTime,
        path: '/'
      });
      Cookies.set('refreshToken', refreshToken, {
        domain: `.${domain}`,
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        path: '/'
      });

      axios.defaults.headers.common['Authorization'] = accessToken;

      // broadcast token change event
      const tokenChangeEvent = new CustomEvent('tokenChange');
      window.dispatchEvent(tokenChangeEvent);

      setIsInitializing(false);
    },
    token,
    tokenExpiration,
    updateToken: async () => {
      try {
        setIsUpdatingToken(true);
        let refreshToken;
        if (isNativeApp) {
          const tokenData = JSON.parse(mobileToken);
          refreshToken = tokenData.refresh_token;
        } else {
          refreshToken = Cookies.get('refreshToken');
        }

        const result = await axios.post('/auth/api/v1/oidc/token', {
          grant_type: 'refresh_token',
          refresh_token: refreshToken
        });

        authConfigStore.getState().setToken(result.data);
        setIsUpdatingToken(false);
      } catch (error) {
        setIsUpdatingToken(false);
        console.error('Failed to refresh token', error);
        await logout(true);
      }
    }
  }));

  useEffect(() => {
    if ((!token && !tokenExpiration) || isUpdatingToken) {
      // If there's no token or already fetching a token, don't start the interval
      return;
    }

    const checkTokenExpiration = () => {
      const expirationTime = new Date(tokenExpiration || null).valueOf();
      const currentTime = new Date().valueOf();

      if (expirationTime < currentTime) {
        authConfigStore.getState().updateToken();
      }
    };

    //checks on page refresh
    checkTokenExpiration();

    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 1000 * 60); // Check every minute

    return () => clearInterval(interval);
  }, [token, tokenExpiration, isNativeApp, isUpdatingToken]);

  return (
    <AuthContext.Provider value={authConfigStore}>
      {isInitializing ? <></> : props.children}
    </AuthContext.Provider>
  );
};
