import { Alert as MaintenanceAlert } from '@hermes/ui';
import { CookieOutlined } from '@mui/icons-material';
import { Alert, Box, Stack, styled } from '@mui/material';
import { usePostLogin } from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import {
  broadcastToApp,
  isEmbeddedBrowser,
  validateEmail
} from '@vestwell-frontend/helpers';
import {
  useDocumentTitle,
  useNotifications,
  useSearchParams
} from '@vestwell-frontend/hooks';
import {
  Button,
  CookiesBanner,
  Form,
  FormField,
  Link,
  Text
} from '@vestwell-frontend/ui';

import Cookies from 'js-cookie';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useLocalStorage } from 'usehooks-ts';
import * as Yup from 'yup';

import { SubmitButton } from '../components';
import { useConfig } from '../contexts';
import { routes } from '../router/routes';

const StyledAlert = styled(Alert)(() => ({
  marginTop: 0,
  width: 'fit-content'
}));

const StyledIcon = styled(CookieOutlined)(({ theme }) => ({
  backgroundColor: theme.palette.water.main,
  borderRadius: theme.spacing(25),
  padding: theme.spacing(1)
}));

export const loginSchema = Yup.object().shape({
  email: validateEmail('Please use standard email format').required('Required'),
  password: Yup.string().required('Required')
});

export function SignInPage() {
  useDocumentTitle('Sign In');

  const notify = useNotifications();
  const navigate = useNavigate();
  const params = useSearchParams();
  const [credentials, setCredentials] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [, setCodeLastSentTime] = useLocalStorage('codeLastSentTime', {});

  const auth = useAuth();
  const config = useConfig();

  const login = usePostLogin({
    mutation: {
      onSuccess: async () => {
        if (await isEmbeddedBrowser()) {
          broadcastToApp('LOGIN', credentials);
        }
        window.location.href = `${window.location.origin}${routes.LOGIN_VERIFICATION}`;
      }
    }
  });

  const submitLogIn = useCallback(
    async values => {
      auth.setToken('');
      setCredentials(values);
      await login.mutateAsync({
        data: {
          password: values.password,
          redirectParams: params,
          username: values.email
        }
      });
    },
    [params, setCredentials]
  );

  const onClick = useCallback(async () => {
    Cookies.set('cookiesBanner', JSON.stringify({ dismissed: true }), {
      expires: 1,
      path: '/'
    });
    setIsOpen(false);
    window.open('https://www.jpmorgan.com/privacy', '_blank');
  }, []);

  useEffectOnce(() => {
    if (params.error) {
      notify.error(
        'You have been logged out due to inactivity. Please log in again.',
        {
          persist: true
        }
      );

      window.history.replaceState({}, '', routes.SIGN_IN);
    }
    setCodeLastSentTime({});
  });

  useUpdateEffect(() => {
    if (login.error?.code === 'LOCKED_OUT') {
      navigate(routes.ACCOUNT_LOCKED);
    }
  }, [login.error]);

  return (
    <>
      <Text color='emphasize' data-testid='signInTitle' mb={8} variant='b2'>
        Sign In
      </Text>
      <MaintenanceAlert />
      <Form
        initialValues={{
          email: '',
          password: ''
        }}
        onChange={login.reset}
        onSubmit={submitLogIn}
        validationSchema={loginSchema}>
        <Stack spacing={2} width={256}>
          <FormField
            autoComplete='on'
            data-testid='emailField'
            format='email'
            label='Email'
            name='email'
            width={64}
          />
          <FormField
            label='Password'
            name='password'
            revealable
            type='password'
            width={64}
          />
          <Stack alignItems='center'>
            {login.error && (
              <StyledAlert severity='error'>{login.error?.message}</StyledAlert>
            )}
            <Link
              data-testid='forgotPasswordLink'
              to={routes.FORGOT_PASSWORD}
              variant='i1'>
              Forgot your password?
            </Link>
            <SubmitButton disabled={!!login.error}>Sign In</SubmitButton>
          </Stack>
        </Stack>
      </Form>
      <Text color='grey100' variant='i2'>
        {config.login?.accountSetup?.link?.description
          ? `${config.login?.accountSetup?.link?.description} `
          : `Don't have an account yet? `}
        <Box
          color={theme => theme.palette.primary.main}
          component='a'
          data-testid='registrationLink'
          href={`${window.location.href.replace('signin', 'connect')}${
            config.login?.accountSetup
              ? routes.ACCOUNT_SETUP.slice(1)
              : config.registration?.isState
                ? routes.EMPLOYER_REGISTRATION.slice(1)
                : routes.REGISTRATION.slice(1)
          }`}
          sx={theme => theme.typography.i1}>
          {config.login?.accountSetup?.link?.text ?? 'Start here'}
        </Box>
      </Text>
      {config.subdomain === 'jpmorgan' && (
        <CookiesBanner
          bannerName='cookiesBanner'
          icon={<StyledIcon color='info' fontSize='medium' />}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          text={
            <Text mb={0} variant='i1'>
              We and our partners use cookies and other tools for advertising,
              to help stop fraud, and for other purposes. By using this site,
              you agree to how your information is used as outlined in our{' '}
              <Button onClick={onClick} variant='inline'>
                Privacy Policy
              </Button>
              .
            </Text>
          }
          title={
            <Text mb={0} variant='i2'>
              Cookies
            </Text>
          }
        />
      )}
    </>
  );
}

SignInPage.displayName = 'SignInPage';
