import { CloseOutlined } from '@mui/icons-material';
import { AlertProps, Box, IconButton, Stack, styled } from '@mui/material';

import Cookies from 'js-cookie';
import { FC, ReactNode, useCallback, useEffect, useId } from 'react';

export type CookiesBannerProps = Pick<AlertProps, 'onClose'> & {
  bannerName: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  icon?: ReactNode;
  text: ReactNode;
  title: ReactNode;
};

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: theme.spacing(1),
  bottom: theme.spacing(6),
  boxShadow: theme.shadows[4],
  maxWidth: theme.spacing(80),
  padding: theme.spacing(4),
  position: 'absolute',
  right: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    bottom: theme.spacing(4),
    maxWidth: '92%',
    right: 'auto'
  }
}));

export const CookiesBanner: FC<CookiesBannerProps> = props => {
  const labelId = useId();

  const onClose = useCallback(async () => {
    Cookies.set(props.bannerName, JSON.stringify({ dismissed: true }), {
      expires: 1,
      path: '/'
    });
    props.setIsOpen(false);
  }, []);

  useEffect(() => {
    const cookie = Cookies.get(props.bannerName);
    const dismissed = cookie ? JSON.parse(cookie).dismissed : false;
    if (!dismissed) {
      props.setIsOpen(true);
    }
  }, []);

  return props.isOpen ? (
    <StyledStack
      aria-labelledby={labelId}
      data-component='cookiesBanner'
      spacing={1}>
      <Stack
        alignItems='center'
        direction='row'
        id={labelId}
        justifyContent='space-between'>
        <Stack alignItems='center' direction='row' spacing={2}>
          {props.icon ? props.icon : null}
          <Box>{props.title}</Box>
        </Stack>
        <IconButton data-component='cookiesBannerCloseButton' onClick={onClose}>
          <CloseOutlined fontSize='small' />
        </IconButton>
      </Stack>
      {props.text}
    </StyledStack>
  ) : undefined;
};

CookiesBanner.displayName = 'CookiesBanner';
